.contact {
  position: relative;
  background-image: url(./images/handshake.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Adjust the opacity as needed */
  z-index: 1;
}

.contact-content {
  position: relative;
  z-index: 2;
}


.services {
  position: relative;
  background-image: url(./images/gray-background.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.services::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85); /* Light gray with 60% opacity */
  z-index: 1;
}

.services-content {
  position: relative;
  z-index: 2;
}

.footer {
  position: relative;
  background-image: url(./images/gray-background.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Light gray with 60% opacity */
  z-index: 1;
}

.footer-content {
  position: relative;
  z-index: 2;
}


.offer {
  position: relative;
  background-image: url(./images/force/post-4.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.offer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Light gray with 60% opacity */
  z-index: 1;
}

.offer:hover::before {
  background-color: rgba(0, 0, 0, 0.5); /* Reduced opacity on hover */
}

.offer-content {
  position: relative;
  z-index: 2;
}


.team-one {
  position: relative;
  background-image: url(./images/latest//image1.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.team-one::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); 
  z-index: 1;
}

.team-one:hover::before {
  background-color: rgba(0, 0, 0, 0.3); /* Reduced opacity on hover */
}

.team-one-content {
  position: relative;
  z-index: 2;
}


.team-two {
  position: relative;
  background-image: url(./images/latest/imga4.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.team-two::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); 
  z-index: 1;
}

.team-two:hover::before {
  background-color: rgba(0, 0, 0, 0.3); /* Reduced opacity on hover */
}

.team-two-content {
  position: relative;
  z-index: 2;
}

.team-three {
  position: relative;
  background-image: url(./images/latest/image6.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-position 0.3s ease; /* Smooth transition */
}

.team-three::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); 
  z-index: 1;
  transition: background-color 0.3s ease; /* Smooth transition */
}

.team-three:hover {
  background-position: left center; /* Move background to the left */
}

.team-three:hover::before {
  background-color: rgba(0, 0, 0, 0.3); /* Reduced overlay opacity on hover */
}

.team-three-content {
  position: relative;
  z-index: 2;
}


.skills {
  position: relative;
  background-image: url(./images/skills.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.skills::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Light gray with 60% opacity */
  z-index: 1;
}

.skills:hover::before {
  background-color: rgba(0, 0, 0, 0.6); /* Reduced opacity on hover */
}

.skills-content {
  position: relative;
  z-index: 2;
}


.one-end {
  background: linear-gradient(320deg, transparent 55px, #2F7F93 0);
}


.hero1 {
  position: relative;
  background-image: url(./images//latest/image2.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); 
  z-index: 1;
}

.hero1-content {
  position: relative;
  z-index: 2;
}


.hero2 {
  position: relative;
  background-image: url(./images/latest/image7.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); 
  z-index: 1;
}

.hero2-content {
  position: relative;
  z-index: 2;
}

.hero1, .hero2 {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.hero1-content, .hero2-content {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.sticky-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 50; /* Ensure it has a high z-index */
  background-color: #000; /* Optional: to ensure it has a background */
}


/* Assuming you are using Tailwind CSS, here is the additional CSS */
.h-68 {
  height: 17rem; /* Adjust this value as needed for your design */
}

.h-auto {
  height: auto;
}

